<template>
  <div class="modal-header">
    <h5 class="modal-title">
      <span v-if="agent.id">Edit an Agent</span>
      <span v-if="!agent.id">Add an Agent</span>
    </h5>

    <button type="button" class="btn-close" @click="modal.close()" />
  </div>

  <div class="modal-body">
    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <form novalidate class="form">
      <div class="row">
        <div class="col-md-6 mb-3">
          <FormInput label="Company" type="text" :validator="v$.form.company_name" />
        </div>

        <div class="col-md-6 mb-3">
          <FormInput label="VAT number" type="text" :validator="v$.form.vat" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3 form-required">
          <FormInput label="first name" :validator="v$.form.firstname" />
        </div>

        <div class="col-md-6 mb-3 form-required">
          <FormInput label="Last name" :validator="v$.form.lastname" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3 form-required">
          <FormInput label="Email" type="email" :validator="v$.form.email" />
        </div>

        <div class="col-md-6 mb-3">
          <FormInput label="Phone" type="phone" :validator="v$.form.phone" />
        </div>
      </div>

      <div class="mb-3">
        <FormTextarea label="Address" rows="3" :validator="v$.form.address" />
      </div>

      <div class="mb-3 form-required">
        <CountrySelect :validator="v$.form.country" />
      </div>

      <fieldset>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <FormInput label="Performance fees" type="number" step="any" :validator="v$.form.special_performance_fee" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <FormInput label="Quarterly fees" type="number" step="any" :validator="v$.form.special_quaterly_fee" />
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>

  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger me-auto" @click="remove(agent)" v-if="agent.id" :disabled="v$.$dirty">
      Remove
    </button>

    <button type="button" class="btn btn-outline-primary" @click="modal.close()">
      Cancel
    </button>

    <button type="button" class="btn btn-primary" @click="save" :disabled="v$.$invalid">
      Save
    </button>
  </div>
</template>

<script>
  import ModalConfirm from '@/components/ModalConfirm'
  import FormInput from '@/components/base/FormInput'
  import FormTextarea from '@/components/base/FormTextarea'
  import CountrySelect from '@/components/CountrySelect'

  import agentService from '@/services/agents.service'

  import useVuelidate from '@vuelidate/core'
  import useModal from '@/plugins/modal/useModal'
  import { required, email, decimal } from '@vuelidate/validators'
  import { formatDate } from '@/utils/date'

  export default {
    components: {
      FormInput,
      FormTextarea,
      CountrySelect
    },

    props: {
      agent: {
        type: Object,
        default: () => ({})
      },
      modal: {
        type: Object,
        default: () => ({})
      }
    },

    setup() {
      return {
        v$: useVuelidate(),
        modal$: useModal()
      }
    },

    data() {
      return {
        form: {
          firstname: '',
          lastname: '',
          company_name: '',
          email: '',
          password: false,
          phone: '',
          address: '',
          country: '',
          vat: '',
          special_quaterly_fee: null,
          special_performance_fee: null
        },
        formError: ''
      }
    },

    watch: {
      agent: {
        immediate: true,
        handler(agent) {
          this.form = { ...this.form, ...agent }
          this.form.inception_date = formatDate(this.form.inception_date, 'yyyy-MM-dd')
        }
      }
    },

    validations() {
      return {
        form: {
          firstname: { required },
          lastname: { required },
          company_name: {},
          email: { required, email },
          password: { required },
          phone: {},
          address: {},
          country: { required },
          vat: {},
          special_quaterly_fee: { decimal },
          special_performance_fee: { decimal }
        }
      }
    },

    methods: {
      remove() {
        this.modal$
          .open(ModalConfirm, {
            type: 'danger'
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await agentService.delete(this.form.id)
                this.modal.close('DELETED')
              } catch (e) {
                this.formError = 'Something went wrong on the server when deleting: ' + e.data.message
              }
            }
          })
      },

      async save() {
        this.formError = ''

        try {
          await agentService.save(this.form)
          this.modal.close('SAVED')
        } catch (e) {
          this.formError = 'Something went wrong on the server: ' + e.data.message
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  fieldset
    border: 1px solid $gray-300
    padding: $spacer $spacer 0
    margin-bottom: $spacer
    background: $gray-100
</style>

