<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <select
      class="form-select"
      v-bind="$attrs"
      v-model="model"
      :id="elmtId"
      :name="name"
      :class="[ 'custom-select-' + size, { 'is-invalid': validator && validator.$error } ]"
      @change="onUpdateValue"
    >
      <option v-if="placeholder" value="">{{ placeholder }}</option>

      <option
        v-for="(optionText, optionKey) in options"
        :value="getValue(optionKey, optionText)"
        :key="optionKey"
      >
        {{ getText(optionKey, optionText) }}
      </option>
    </select>
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        type: [ Array, Object ],
        default: () => []
      },
      fieldKey: {
        type: String,
        default: 'id'
      },
      fieldText: {
        type: [ Array, String ],
        default: 'name'
      },
      size: {
        type: String,
        default: ''
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: [ Number, String ],
        default: ''
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        elmtId: this.id || generateId(),
        model: null
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model || ''
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue || ''
          }
        }
      }
    },

    methods: {
      getValue(key, value) {
        if (Array.isArray(this.options)) {
          if (typeof value === 'object') {
            return value[this.fieldKey]
          }

          return value
        }

        return key
      },

      getText(_, value) {
        if (typeof value === 'object') {
          if (Array.isArray(this.fieldText)) {
            const items = []

            this.fieldText.forEach((key) => {
              items.push(value[key])
            })

            return items.join(' ')
          } else {
            return value[this.fieldText]
          }
        }

        return value
      },

      onUpdateValue() {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = this.model
        }
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>
