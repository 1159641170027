// Remove accents
function normalize(string) {
  return ('' + string).normalize('NFD').toUpperCase().replace(/[\u0300-\u036f]/g, '')
}

function valueFromDotNotation(string, object) {
  return string.split('.').reduce((o, i) => o[i], object)
}

export function filterCollection(collection, filter, fields = []) {
  if (!filter) {
    return collection
  }

  return collection.filter((item) => {
    const found = []

    fields.forEach((key) => {
      const value = valueFromDotNotation(key, item)

      found.push(normalize(value).includes(normalize(filter)))
    })

    return found.some((value) => !!value)
  })
}
