<template>
  <div class="CountrySelect">
    <FormSelect
      v-bind="$attrs"
      v-model="model"
      label="Country"
      field-key="code"
      field-text="name"
      :placeholder="placeholder"
      :options="countries"
      @change="onChange"
    />
  </div>
</template>

<script>
  import FormSelect from '@/components/base/FormSelect'

  import countries from '@/data/countries.json'

  export default {
    components: {
      FormSelect
    },

    props: {
      modelValue: {
        type: String,
        default: null
      },

      placeholder: {
        type: String,
        default: '— Select a Country —'
      }
    },

    emits: [
      'update:modelValue'
    ],

    data() {
      return {
        model: this.modelValue,
        countries
      }
    },

    methods: {
      onChange() {
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>
