<template>
  <main class="AgentsView">
    <div class="container">
      <header class="mb-3 d-flex">
        <h1 class="m-0">Agents</h1>

        <div class="ms-auto AgentsView__filters">
          <input
            ref="filter"
            type="text"
            class="form-control form-control-sm w-50 mb-3"
            v-model="filter"
            placeholder="Filter agents…"
          >

          <button class=" btn-sm btn btn-primary" @click="openForm()">
            Add Agent
          </button>
        </div>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !agents.length" />

      <div
        v-if="!loading && agents.length"
      >
        <section class="AgentsView__list mb-5">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="AgentsView__table table">
                  <colgroup>
                    <col style="width: 40px">
                    <col>
                    <col>
                    <col>
                    <col>
                    <col style="width: 80px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th />
                      <th>Last name</th>
                      <th>First name</th>
                      <th>Company</th>
                      <th>Email</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="agent in filteredAgents" :key="agent.id">
                      <td>
                        <UserAvatar :user="agent" />
                      </td>
                      <td>
                        {{ agent.lastname }}
                      </td>
                      <td>
                        {{ agent.firstname }}
                      </td>
                      <td>
                        {{ agent.company_name || '-' }}
                      </td>
                      <td>
                        <a :href="'mailto:' + agent.email">
                          {{ agent.email }}
                        </a>
                      </td>
                      <td class="text-end">
                        <DropdownMenu align="end">
                          <i class="bi bi-gear" />

                          <template #menu>
                            <DropdownItem @click="openForm(agent)">
                              Edit
                            </DropdownItem>
                            <DropdownItem @click="regeneratePassword(agent)">
                              Re-generate password
                            </DropdownItem>
                          </template>
                        </DropdownMenu>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppEmptyList from '@/components/AppEmptyList'
  import UserAvatar from '@/components/UserAvatar'
  import ModalAgentForm from '@/components/ModalAgentForm'
  import ModalConfirm from '@/components/ModalConfirm'
  import DropdownMenu from '@/components/base/DropdownMenu'
  import DropdownItem from '@/components/base/DropdownItem'

  import agentService from '@/services/agents.service'

  import useModal from '@/plugins/modal/useModal'
  import useToast from '@/plugins/toast/useToast'
  import { formatRelativeDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { filterCollection } from '@/utils/filters'
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      AppLoading,
      AppEmptyList,
      UserAvatar,
      DropdownMenu,
      DropdownItem
    },

    setup() {
      return {
        modal$: useModal(),
        toast$: useToast()
      }
    },

    data() {
      return {
        filter: '',
        loading: true,
        agents: null
      }
    },

    computed: {
      ...mapState('auth', [
        'impersonated'
      ]),

      filteredAgents() {
        return filterCollection(this.agents, this.filter, [ 'firstname', 'lastname', 'company_name', 'email' ])
      }
    },

    mounted() {
      this.load()
    },

    methods: {
      ...mapActions('agents', {
        updateAgentList: 'updateList'
      }),

      formatRelativeDate,
      formatDecimal,

      async load() {
        this.loading = true
        this.agents = await agentService.fetch()
        this.loading = false

        this.updateAgentList(this.agents)
      },

      regeneratePassword(agent) {
        const title = 'Regenerate password'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm re-generating a new password for  <b>' + agent.firstname + ' ' + agent.lastname + '</b>'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await agentService.regeneratePassword(agent.id)
                this.toast$.success(title, 'A new password has been generated.')
              } catch (e) {
                console.error(e)
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      },

      openForm(agent) {
        this.modal$
          .open(ModalAgentForm, {
            closeOnBackdrop: false,
            scrollable: true,
            props: {
              agent
            }
          })
          .onClose((response) => {
            if ([ 'DELETED', 'SAVED' ].includes(response)) {
              this.load()
            }
          })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AgentsView__filters
    display: flex
    align-items: center
    justify-content: center
    gap: 1em

    input.form-control
      height: 100%
      margin: 0 !important
      width: 150px !important

  .AgentsView__table
    margin-bottom: 150px

    tr
      vertical-align: middle

      &.active
        th, td
          background: lighten($secondary, 42)
          font-weight: bold

      &.active.table-danger
        th, td
          background: lighten($danger, 42)

    th, td
      white-space: nowrap

    .dropdown
      display: inline-block

  .AgentsView__table__logo
    width: 32px
    height: 32px
</style>
